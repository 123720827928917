import { render, staticRenderFns } from "./add_broadcast_goods.vue?vue&type=template&id=ca7ded36&scoped=true&"
import script from "./add_broadcast_goods.vue?vue&type=script&lang=ts&"
export * from "./add_broadcast_goods.vue?vue&type=script&lang=ts&"
import style0 from "./add_broadcast_goods.vue?vue&type=style&index=0&id=ca7ded36&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca7ded36",
  null
  
)

export default component.exports