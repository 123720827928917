






























































































































































import { Component, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import { PageMode } from '@/utils/type'
import MaterialSelect from '@/components/material-select/index.vue'
import LsGoodsSelect from '@/components/live-broadcast/ls-goods-select.vue'
import { apiLiveGoodsAdd } from '@/api/application/live_broadcast'
import config from '@/config'
import { throttle } from '@/utils/util'
@Component({
    components: {
        MaterialSelect,
        LsGoodsSelect
    }
})
export default class AddBroadcastGoods extends Vue {
    action = `${config.baseURL}/adminapi/upload/wechatMaterial`
    version = config.version
    ishide = false

    mode: string = PageMode.ADD // 当前页面【add: 添加 | edit: 编辑】

    goodsType = '1'

    form = {
        name: '', // 商品名称，最长14个汉字
        image: '', // 商品封面图
        price_type: 1, // 价格类型，1：一口价（只需要传入price，price2不传） 2：价格区间（price字段为左边界，price2字段为右边界，price和price2必传） 3：显示折扣价（price字段为原价，price2字段为现价， price和price2必传）
        price: 0, // 数字，最多保留两位小数，单位元
        price2: 0, // 数字，最多保留两位小数，单位元
        url: '' // 商品详情页的小程序路径
    }

    allPrice = {}

    formRules = {
        name: [
            {
                required: true,
                message: '请输入商品名称',
                trigger: 'blur'
            }
        ],
        image: [
            {
                required: true,
                message: '请选择图片',
                trigger: 'blur'
            }
        ],
        url: [
            {
                required: true,
                message: '请输入商品链接',
                trigger: 'change'
            }
        ],
        price_type: [
            {
                required: true,
                message: '请选择价格类型',
                trigger: 'change'
            }
        ]
    }
    $refs!: {
        formRef: any
    }

    selecGoods = {} // 选中的商品

    // 获取选择用户
    getGoods(val: any) {
        // 未选中用户不修改
        if (val == null) {
            return
        }
        this.selecGoods = val
        this.form.url = 'pages/goods_detail/goods_detail?id=' + val.id
        this.form.name = val.name
        this.form.price = val.min_price
    }

    // 提交
    onSubmit() {
        // 验证表单格式是否正确
        this.$refs.formRef.validate((valid: boolean): any => {
            if (!valid) {
                return
            }
            // 请求发送
            // switch (this.mode) {
            // 	case PageMode['ADD']:
            // 		return this.handleUserLabelAdd()
            // 	case PageMode['EDIT']:
            // 		return this.handleUserLabelEdit()
            // }
            this.liveGoodsAdd()
        })
    }

    // 创建直播商品
    liveGoodsAdd() {
        apiLiveGoodsAdd(this.form)
            .then((res: any) => {
                setTimeout(() => this.$router.go(-1), 500)
            })
            .catch((err: any) => {})
    }

    handleRemove(file: any, fileList: any) {
        this.ishide = false
    }
    handleSuccess(response: any, file: any, fileList: any[]) {
        this.form.image = response.data.media_id
        this.ishide = true
    }
    handleError(err: any, file: any) {
        this.$message.error(`${file.name}文件上传失败`)
    }
    handleExceed() {
        this.$message.error('超出上传上限，请重新上传')
    }

    created() {
        this.onSubmit = throttle(this.onSubmit, 1000)
    }
}
