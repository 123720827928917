

































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { apiGoodsLists } from '@/api/goods'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
@Component({
    components: {
        LsPagination
    }
})
export default class LsGoodsSelect extends Vue {
    // @Prop() value ? : number
    @Prop({
        default: '选择商品'
    })
    title!: string //弹窗标题
    @Prop({
        default: '880px'
    })
    width!: string | number //弹窗的宽度
    @Prop({
        default: '10vh'
    })
    top!: string | number //弹窗的距离顶部位置
    /** S Data **/
    visible = false

    // 查询表单
    form = {
        keyword: '' // 商品名
    }
    pager: RequestPaging = new RequestPaging()
    // 被选中的列
    currentRow = {}
    /** E Data **/

    /** S Methods **/
    // 触发打开弹窗
    onTrigger() {
        this.getList()
        this.visible = true
    }

    // 关闭弹窗
    close() {
        this.visible = false
        // 重制表单内容
        this.form.keyword = ''
    }
    // 确定选中
    onSubmit() {
        if (JSON.stringify(this.currentRow) == '{}') {
            return this.$message.error('请选择商品')
        }
        if (this.currentRow == null) {
            return this.$message.error('请选择商品')
        }

        this.$emit('getGoods', this.currentRow)
        this.visible = false
    }

    // 获取用户列表
    getList(): void {
        this.pager
            .request({
                callback: apiGoodsLists,
                params: {
                    ...this.form
                }
            })
            .then((res: any) => {})
    }

    // 重置
    onReset() {
        this.form.keyword = ''

        this.getList()
    }
    // 获取选中的列
    handleCurrentChange(val: any) {
        //
        this.currentRow = val
    }
    /** E Methods **/
}
